.iconContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  & svg {
    height: 20px;
    width: 20px;
  }
}
