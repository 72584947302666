@import '../../../src/styles/sitewide.module';

$padding: 8px;
$borderWidth: 1px;
$activeBorderWidth: 2px;
$activeColor: #fff;
$selectedColor: #1c3ceb;
$disabledFontColor: #666;
$activeBorderColor: #3d87f5;
$borderColor: #a9ad9d;
$hoverColor: #ddd;
$hoverBorderColor: #0000cc;

$mustFillHighlightOpacity: 25%;
$mustFillHighlightCellColor: rgb(238, 255, 99);

$fontSize: 14px;

$addColor: #78BE7C;
$addFontColor: #0e8319;
$removeColor: #E49CA1;
$removeFontColor: #69080d;
$updateColor: #F9DA7D;
$updateFontColor: #866506;

$adminDefaultColumnWidth: 150px;
$customerDefaultColumnWidth: 150px;
$pageColumnWidth: 50px;
$zoneNumberColumnWidth: $pageColumnWidth;


.cellFilters {
  padding-top: 15px;
  padding-right: 5px;
}

.cellContainer,
.cellHeaderContainer {
  box-sizing: border-box;

  position: relative;

  font-size: $fontSize;
}

// Prevent border collapsing
.cellContainer {
  border: $borderWidth solid $borderColor;
  border-right: none;
  border-bottom: none;
  padding: 5px;

  background: inherit;

  &:hover {
    border-color: $hoverBorderColor;
    border-right: $borderWidth solid $hoverBorderColor;
    border-bottom: $borderWidth solid $hoverBorderColor;

    background-color: $hoverColor;
  }

  .cell {
    @include multiLineTruncate;
  }
}

.cellHeaderContainer {
  background-color: #F9FAFA;

  .cell {
    white-space: nowrap;
  }
}

.columnSearch {
  display: block;
  margin-bottom: 10px;
}

.contentWrap {
  .cell {
    @include multiLineShow;
  }
}

.mustFillHighlightCell {
  background: rgba($mustFillHighlightCellColor, $mustFillHighlightOpacity);
}

.mustFillHighlightHeaderCell {
  background-color: color-mix(in srgb, white, $mustFillHighlightCellColor $mustFillHighlightOpacity);
}

.active {
  padding: 0;
  width: 100%;

  border: 2px solid blue;
}

.left {
  text-align: left;
}

.right {
  text-align: right;
}

.center {
  text-align: center;
}

.activeInput {
  border: $activeBorderWidth solid $activeBorderColor;
  padding: $padding - $borderWidth;
  padding-inline: $padding - $activeBorderWidth;
  display: block;
  width: calc(100% - #{$padding * 2});
  font-size: $fontSize;
  background-color: $activeColor;

  &:focus {
    outline: none;
  }
}

.disabledInput {
  display: block;
  width: calc(100% - #{$padding * 2});
  border: $borderWidth solid $activeBorderColor;
  padding: $padding - $borderWidth;

  color: $disabledFontColor;
  font-size: 1rem;
  background-color: $activeColor;

  overflow: scroll;
  text-overflow: initial;
}

.errorIcon {
  position: absolute;
  top: 0;
  right: 0;
  border-top: 6px solid red;
  border-right: 6px solid red;
  border-left: 6px solid transparent;
  border-bottom: 6px solid transparent;
}

.errorText {
  background-color: white;
  position: absolute;
  padding: 4px;
  right: 0;
  border: 1px solid #777;
  z-index: 10;
}

.topErrorText {
  @extend .errorText;
  bottom: 32px;
  white-space: pre-line;
}

.bottomErrorText {
  @extend .errorText;
  top: 32px;
  white-space: pre-line;
}

.sticky {
  z-index: 8;
  position: sticky;
  left: 0;
}

.header {
  border-bottom: $borderWidth solid $borderColor;
}

.floatingLink {
  position: absolute;
  right: 1px;
  top: 1px;
  padding: 2px;
  z-index: 10;
  background-color: $hoverColor;
}

.menuItemWithIcon {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

// Highlighting
.add {
  background-color: $addColor;
  color: $addFontColor;
}

.remove {
  background-color: $removeColor;
  color: $removeFontColor;
}

.update {
  background-color: $updateColor;
  color: $updateFontColor;
}

.error {
  color: #c82020;
  font-weight: bold;
}
/**
  There should be no overlap between the borders when in Selection mode.
  Therefore, we remove the right border from cells by default. In order to avoid the border overlap
  between the top and bottom borders, we remove the top border for each cell in a selected row.
 */
.selected {
  border: $borderWidth dashed $selectedColor;
  border-right: none;
}

// See GridViewRow.module.scss for .isSelected
// Prevent border collapsing
:global(tr[class*="isSelected"]) {
  + tr[class*="isSelected"] {
    .selected {
      border-top: none;
    }
  }
}

// Custom columns width

.defaultColumnWidth {
  box-sizing: border-box;
}

.adminColumnWidth {
  @include setWidthRangeLimit($adminDefaultColumnWidth);
}

.customerColumnWidth {
  @include setWidthRangeLimit($customerDefaultColumnWidth);
}

.adminColumnWidth,
.customerColumnWidth {
  &.pageColumnWidth,
  &.zoneNumberColumnWidth {
    @include setWidthRangeLimit(75px);
  }

  &.isLocalColumnWidth {
    @include setWidthRangeLimit(90px);
  }

  &.vendorManagerColumnWidth {
    @include setWidthRangeLimit(95px);
  }

  &.isAplentyColumnWidth,
  &.isPrimeBenefitColumnWidth {
    @include setWidthRangeLimit(100px);
  }
  &.primeMemberLabelColumnWidth {
    @include setWidthRangeLimit(110px);
  }
  &.unitOfMeasureColumnWidth,
  &.discountTagColumnWidth {
    @include setWidthRangeLimit(115px);
  }
  &.promotionTypeColumnWidth,
  &.circularPriceColumnWidth,
  &.eachPriceForF3RSColumnWidth,
  &.imageStatusColumnWidth,
  &.pawsIdColumnWidth,
  &.primeDiscountColumnWidth,
  &.promotionEndDateColumnWidth,
  &.statusColumnWidth,
  &.isFreshBrandColumnWidth,
  &.isPrivateBrandColumnWidth,
  &.isSoftDeletedColumnWidth,
  &.isUSDAChoiceColumnWidth,
  &.storeIdsColumnWidth
  {
    @include setWidthRangeLimit(120px);
  }
  &.isUSDAOrganicColumnWidth {
    @include setWidthRangeLimit(130px);
  }
  &.isCategoryHeroColumnWidth {
    @include setWidthRangeLimit(135px);
  }
  &.asinImageColumnWidth {
    @include setWidthRangeLimit(150px);
  }
  &.isCoverPageHeroColumnWidth,
  &.productCategoryColumnWidth {
    @include setWidthRangeLimit(155px);
  }
  &.verticalColumnWidth {
    @include setWidthRangeLimit(165px);
  }
  &.storeRegionsColumnWidth {
    @include setWidthRangeLimit(180px);
  }

  &.asinsColumnWidth,
  &.titleColumnWidth,
  &.descriptionColumnWidth,
  &.notesColumnWidth {
    @include setWidthRangeLimit(250px);
  } 
}

.popOutEditCell {
  position: absolute;
  z-index: 999;
  top: 0;
  left: 0;

  width: 100%;
}

.mustFillAsterisk {
  position: absolute;
  z-index: 5;

  padding-inline: 2px;

  color: red;
}