$submitButtonContainerHeight: 50px;

$formContainerBgColor: white;
$formContainerSpaceFromNeighbour: 15px;
$formContainerBorderRadius: 10px;

$formButtonContainer: 80px;

$formWrapperInnerSubmitButtonWidth: 5vw;

$formGroupWrapperSpaceAround: 10px;

$formGroupTitleCounterWidth: 40px;
$formGroupTitleCounterBackground: #077398;

$formGroupTitleFontSize: 20px;
$formGroupTitleBackground: rgba(0, 0, 0, 0.05);
$formGroupTitleHeight: 30px;
$formGroupTitleSpaceAround: 10px;
$formGroupTitleSpaceFromCounter: 50px;

.closeButton {
  position: absolute;
  top: 4px;
  right: 0;
}

.wrapper {
  display: flex;
  justify-content: center;
  flex-direction: column;

  padding-top: 10px;
}

.wrapperInner {
  &.submitButtonWrapper {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    width: $formWrapperInnerSubmitButtonWidth;

    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
}

.form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  background: transparent;
  counter-reset: section;
}

.formInnerContainer {
  max-width: 1000px;
}

.formControl {
  width: 50%;
  margin-bottom: 5px;
}

.formControlHidden {
  display: none;
}

.formControlCheckbox {
  .controlBox {
    min-height: 28px;
    border: 1px solid #6C7778;
    border-radius: 5px;
    padding-left: 5px;
    padding-top: 2px;

    background: #ffffff;
  }
}

.formControl {
  padding-left: 5px;
}

.formHint {
  padding: 0;
  margin: 0;
  margin-top: 5px;

  font-size: 10px;
  color: gray;
}

.controlPanel {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  min-height: $formButtonContainer;

  border-top: 1px solid lightgray;
}

.warnings {
  margin-top: 5px;
}

.rightSideButtonContainer {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

.rightSideButtonContainer button {
  margin-top: 5px;
  margin-left: 5px;
}

.submitButtonContainer {
  display: flex;
  justify-content: flex-end;

  min-height: $submitButtonContainerHeight;

  background: #f9fafa;
  overflow: hidden;
}

.formGroup {
  position: relative;

  margin-top: $formContainerSpaceFromNeighbour;
  border-radius: $formContainerBorderRadius;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;

  &:first-of-type {
    margin-top: auto;
  }

  background: $formContainerBgColor;
  overflow: hidden;
}

.formGroup::before {
  position: absolute;

  content: "";

  border-top: 1px solid #e2dfdf5c;
  width: 100%;
}

.formGroup:first-of-type::before,
.formGroup:last-of-type::before {
  border-top: none;
}

.formGroupWrapper {
  display: flex;
  justify-content: space-around;

  padding: $formGroupWrapperSpaceAround;
}

.formGroupTitle {
  position: relative;
  display: flex;

  height: $formGroupTitleHeight;
  padding: $formGroupTitleSpaceAround;
  padding-left: $formGroupTitleSpaceFromCounter;
  padding-bottom: 0;

  font-size: $formGroupTitleFontSize;
  font-weight: bold;
  background: $formGroupTitleBackground;

  &::before {
    position: absolute;
    top: 0;
    left: 0;

    display: flex;
    justify-content: center;
    align-items: center;

    width: $formGroupTitleCounterWidth;
    height: 100%;

    background: $formGroupTitleCounterBackground;
    color: white;

    counter-increment: section;
    content: counter(section);
  }
}

.formGroupLabel {
  max-width: 30%;
}

.formGroupLabelWrapper {
  width: initial;
}

.formGroupContent {
  display: flex;
  flex-wrap: wrap;

  width: 70%;
  margin-left: 10px;
}

.formGroupSection {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.stretchSection {
  width: 100%;
}

.formGroupDescription {
  padding: 0;
  margin: 0;

  font-size: 12px;
  color: gray;
}

.fullSizeContainer,
.fullSizeFormControl {
  min-width: 100%;
}

.controlBox {
  position: relative;
}

.controlRequiredMark {
  position: absolute;
  right: 3px;
  z-index: 5;

  color: red;
}
