$userPanelWidth: 200px;
$spaceFromNeighbour: 15px;
$spaceFromUserSection: $spaceFromNeighbour * 2;

$linkFontWeight: 400;
$linkFontSize: 13px;

.avatarSection,
.userSection,
.signOutSection {
  margin-left: $spaceFromNeighbour
}

$notificationsContainerMinWidth: 90px;

.userPanel {
  display: flex;

  min-width: $userPanelWidth;
  margin: 0;
}

.notifications {
  display: flex;
  justify-content: center;
  align-items: center;

  min-width: $notificationsContainerMinWidth;
}

.userSection {
  display: flex;
  justify-content: center;
  align-items: center;

  .welcomeMessage {
    font-style: italic;
    font-weight: $linkFontWeight;
  }

  .actions a {
    font-size: $linkFontSize;
  }
}

.signOutSection {
  display: flex;
  justify-content: center;
  align-items: center;

  margin-left: $spaceFromUserSection;
}