@import '../../../styles/sitewide.module';

.treeSelectContainer {
  box-sizing: border-box;
  background: white;
}

.selectControlsContainer {
  @include reset-list-props;
  @include reset-box-model-props;

  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 5px;
}

.selectControlsItem:not(:first-of-type) {
  margin-left: 5px;
}

.selectContainer {
  display: flex;

  position: relative;

  border: 1px solid lightgray;
  border-radius: 5px;
}

.dropdownSelectControl {
  display: flex;
  align-items: flex-start;
  justify-content: center;

  width: 20%;
}

.list {
  @include reset-list-props;

  max-height: 300px;
  overflow: scroll;
}

.item {
  padding: 10px;

  &:hover {
    background-color: #E7E9E9;
  }
}

.tagList {
  @include reset-list-props;
  @include hideScrollbar;

  display: flex;
  justify-content: flex-start;
  align-items: center;

  width: 100%;
  flex-wrap: wrap;
  max-height: 30vh;

  font-size: 14px;
  overflow: scroll;
}

.itemSelected {
  background-color: rgb(7, 115, 152);

  > label {
    color: white;
  }
}

.tagItem {
  display: flex;
  justify-content: center;

  margin: 4px;
  border-radius: 5px;
  border: 2px solid rgb(7, 115, 152);
  padding: 3px;
  min-width: 30px;

  cursor: pointer;

  &:hover {
    background-color: rgb(7, 115, 152);
    color: white;
  }
}

.tagItemNoContent {
  justify-content: flex-start;
  width: 100%;
  border: none;

  &:hover {
    background-color: transparent;
  }

  .tag {
    display: flex;
    justify-content: center;
    align-items: center;

    font-size: 14px;
    color: gray;
  }
}

.controlRequiredMark {
  position: absolute;
  right: 3px;
  z-index: 5;

  color: red;
}