@import '../../../../src/styles/sitewide.module';

$hintLinkFontSize: 12px;

.container {
  @include reset-box-model-props;

  position: relative;

  /**
    Meridian's default style override
   */
  a {
    font-size: $hintLinkFontSize;
  }
}