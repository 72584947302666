$inactiveFontColor: #3b3b3b;
$activeRowBorderColor: #67a0f5;
$lineHeight: 14px;

.default {
  background-color: #f1f1f1;
}
.primeBenefit {
  background-color: #E2F2FE;
}
.highlightedPrimeBenefit {
  background-color: #EAF2F8;
}
.edlp {
  background-color: #C6E7C8;
}
.highlightedEdlp {
  background-color: #DDF3DB;
}
.locked {
  background-color: #d5d5d5;
  color: $inactiveFontColor;
}
.active td:not(:global([class*='active'])) {
  background-color: white;
  border-top: 1px solid $activeRowBorderColor;
  border-bottom: 1px solid $activeRowBorderColor;
}
.highlight {
  background-color: white;
}

.crossedOut {
  text-decoration: line-through;
  background-color: #E6E6E6;
  color: $inactiveFontColor;
}
.row {
  line-height: $lineHeight;
}

.lockButton {
  padding-inline: 8px;
}

.isSelected {
  outline: transparent;
}

.isSticky {
  position: sticky;
  top: 0;
  z-index: 100;

  border-bottom: 1px solid #ccc;
}

/*
  Transform popover window look (for the last 5 rows) to be displayed upside down
  (as it might overflow and go beyond the view port in case there is a lot of text inside).
 */
.row:nth-last-child(-n+5) {

  :global(div[class*="popOver"]) {
    top: auto;
    bottom: 100%;
  }
}