$hoverColor: #077398;

.columnView {
  height: 400px;
  overflow-y: scroll;
}
.columnRow {
  display: flex;
  width: 100%;
  border: 1px solid transparent;
}
.columnDropped {
  animation: splash 0.3s normal forwards ease-in-out;
  animation-iteration-count: 1;
}
.columnCheck {
  flex-grow: 1;
  position: relative;
}
.columnIcon {
  padding: 0 4px;
  border-radius: 4px;
  cursor: grab;
}
.hoverAbove {
  border-top: 1px solid $hoverColor;
}
.hoverBelow {
  border-bottom: 1px solid $hoverColor;
}

@keyframes splash {
  from {
    background-color: white;
  }
  50% {
    background-color: $hoverColor;
  }
  to {
    background-color: white;
  }
}