.imagePickerContainer {
  display: flex;
  flex-wrap: wrap;
  padding: 15px;
  align-items: center;
  max-height: 265px;
  overflow-y: auto;
  border: 1px solid #6c7778;
  border-top: 0;
  margin-top: -5px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;

  .imageGallery {
    border: 1px solid #6c7778;
    border-radius: 5px;
    margin-top: 10px;
    overflow: auto;
    max-height: 140px;
  }

  .bynderGallery {
    align-items: center;
    justify-content: space-between;
    border: 1px solid #6c7778;
    border-radius: 5px;
    max-height: 75%;

    img {
      height: auto;
      width: 165px;
    }
  }

  .imageGallery > div {
    flex: 0 1 100%;
    color: #6c7778;
    font-size: 14px;
    margin-bottom: 10px;
  }

  img {
    cursor: pointer;
    border: 1px solid transparent;
    padding: 5px;
    height: 40px;
    width: 40px;

    &:hover,
    &.activeImage {
      border-color: #6c7778;
      border-radius: 5px;
    }
  }
}

