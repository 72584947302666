@import '../../../src/styles/sitewide.module';

.container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.wrapper {
  width: 90vw;

  background: white;
  padding: 10px;
  margin-top: 20px;
}

.tabsContainer {
  margin-top: 20px;

  background: white;
}
