$avatarWidth: 50px;
$avatarHeight: $avatarWidth;
$avatarBorerRadius: 50px;
$avatarShadow: inset 0 0 0.1rem rgba(0,0,0,0.3), 0 0 0.4rem -0.1rem rgba(0,0,0,0.8);

.avatar {
  width: $avatarWidth;
  height: $avatarHeight;
  border-radius: $avatarBorerRadius;

  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  box-shadow: $avatarShadow;
}

.disableShadow {
  box-shadow: initial;
}