.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
}

.wrapper {
  max-width: 80vw;
  padding: 10px;
  border-radius: 5px;

  background: white;
}

.innerContainer {
  margin: 15px;
}