.wrapper {
  display: flex;
}

.wrapperInner {
  margin-top: 10px;
  width: 100%;
}

.scrollableContent {
  width: 100%;
  overflow: scroll;
}