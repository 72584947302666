@import '../../../styles/sitewide.module';

$hoverColor: #077398;

$baseTagSize: 110px;
$baseFontSize: 14px;

$gridViewTagSize: $baseTagSize + 10px;
$gridViewFontSize: $baseFontSize - 1px;

$spaceFromTagList: 10px;

.contentContainer {
  display: flex;
  flex-direction: column;
}

.tagsInputContainer {
  position: relative;

  border: 1px solid #ccc;
  padding: 10px;

  background: white;
}

.tagList {
  display: flex;
  flex-wrap: wrap;

  max-height: 200px;
  padding: 0;
  margin: 0;

  overflow-x: hidden;
  overflow-y: scroll;

  list-style: none;
}

.tagItem {
  display: flex;
  justify-content: space-between;

  padding: 5px 10px;
  margin-right: 5px;
  margin-bottom: 5px;
  width: 110px;
  border: 1px solid transparent;
  border-radius: 20px;

  background-color: #f2f2f2;
  color: #333;
  font-size: 14px;
}

.tagsInputContainer input[type="text"] {
  width: 90%;
  padding: 5px;
  border: none;

  font-size: 14px;

  outline: none;
}

.tagsInputContainer input[type="text"]:focus {
  outline: none;
}

.deleteButton {
  padding: 0;
  margin-left: 5px;
  border: none;

  background-color: transparent;
  color: #999;

  cursor: pointer;
}

.inputContainer {
  width: 100%;
}

.inputControlContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.controlButtons {
  display: flex;
  justify-content: flex-end;
}

.inputControl {
  background: #d3d3d33b;
}

.tagDropped {
  animation: splash 0.3s normal forwards ease-in-out;
  animation-iteration-count: 1;
}

.hoverAbove {
  border-top: 1px solid $hoverColor;
}

.hoverBelow {
  border-bottom: 1px solid $hoverColor;
}

.label,
.errorContainer {
  font-size: 12px;
}

.label {
  @include reset-list-props;

  color: gray;
}

.tagCounter {
  @include reset-box-model-props;

  margin-top: 10px;

  font-size: 10px;
  color: gray;
}

.gridViewMode {
  .tagsInputContainer {
    width: 200%;
  }

  .tagItem {
    width: $gridViewTagSize;
    font-size: $gridViewFontSize;
  }

  .inputControlContainer {
    padding-top: $spaceFromTagList;
  }
}

@keyframes splash {
  from {
    background-color: white;
  }
  50% {
    background-color: $hoverColor;
  }
  to {
    background-color: white;
  }
}