@import '../../styles/sitewide.module';
@import '../GridViewCell/GridViewCell.module';

.contentContainer {
  max-height: 65vh;

  overflow: scroll;
}

.colorLegendContainer {
  padding: 10px;
}

.section {
  display: flex;
  flex-direction: column;
}

.sectionContainer {
  display: flex;
  flex-direction: column;
}

.sectionHeader {
  @include reset-box-model-props;

  margin: 30px 0 10px 0;
}

.sectionContent {
  margin-top: 10px;
}

.list {
  @include reset-list-props;
}

.listItem {
  display: flex;
  flex-direction: column;

  margin-top: 10px;
}

.itemContent {
  margin-top: 10px;
}

.overrideHighlightActiveCell {
  td {
    @extend .activeInput;
  }
}

.splitViewSection {
  display: flex;

  .leftSideContent,
  .rightSideContent {
    max-width: 350px;
  }

  .rightSideContent {
    margin-left: 20px;
  }
}