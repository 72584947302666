@import 'src/styles/sitewide.module';

.list {
  @include reset-list-props;

  margin-top: 20px;
}

.item {
  @include reset-box-model-props;
}

.buttonsContainer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.buttons {
  display: flex;
  margin-left: 10px;

  > div {
    margin-left: 5px;
  }
}

.controlsContainer {
  display: flex;
  justify-content: space-between;
}