@import '../../../src/styles/sitewide.module';

.list {
  @include reset-list-props;
}

.item {
  @include reset-box-model-props;

  &:not(:first-of-type) {
    margin-top: 20px;
  }
}

.description {
  display: flex;
}

.version {
  @include reset-box-model-props;

  margin-bottom: 10px;
}

.dateTime {
  margin-left: 5px;
}