@import '../../../src/styles/sitewide.module';

$padding: 8px;
$spaceFromContentUploaderSection: 20px;
$spaceFromSectionTitle: 10px;

.spacer {
  text-align: center;
  margin: 20px 0;
}
.notice {
  text-align: center;
  margin: 8px;
  background-color: #eee;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 8px;
}
.noticeWrapper {
  padding-top: 44px;
}
.actions {
  display: inline-flex;
  margin-left: 16px;
}
.button {
  margin: 0 4px;
}
.dragArea {
  background-color: #eee;
  margin: $padding;
  padding: $padding;
  border: 1px solid #ccc;
  border-radius: 4px;
  &.dragOver {
    background-color: #e0e0ee;
  }
}
.messaging {
  margin: 8px;
}
.linkBar {}
.tabLink {
  color: black;
  &:visited {
    color: black;
  }
}

.validationControls {
  margin-top: $spaceFromContentUploaderSection;
}

.validationModesList {
  @include reset-list-props;

  margin-top: $spaceFromSectionTitle;
}

.gridContainer {
  max-height: 65vh;

  overflow: scroll;
}

.errorDetailsInfo {
  margin-bottom: 15px;
}

.errorDetailsTitle {
  @include reset-box-model-props;

  margin-bottom: 10px;
}

.errorList {
  @include reset-list-props;
  @include reset-box-model-props;

  max-height: 200px;
  overflow: scroll;
}

.errorItem {
  margin-bottom: 10px;
}