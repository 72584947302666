.gridViewTable {
  border-collapse: separate;
  border-spacing: 0;
  position: relative;
  z-index: 0;

  background: #F9FAFA;
}

.gridViewTableWithPasteHereRow {
  tr:last-of-type td {
    background: white;
  }
}

.loaderContainer {
  min-height: 20vh;
}