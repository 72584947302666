@import 'src/styles/sitewide.module';

.list,
.weeksList {
  @include reset-list-props;
  @include reset-box-model-props;
}

.weekItem {
  margin-top: 20px;
}

.gridContainer {
  max-height: 65vh;

  overflow: scroll;
}