@import '../../../src/styles/sitewide.module';

.container {
  width: 90vw;
}

.description {
  margin-top: 20px;
}

.list {
  @include reset-list-props;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.listItem {
  width: 100%;
}

.gridContainer {
  overflow: scroll;
}

.weekTitle {
  display: flex;
  justify-content: space-between;
}