$wrapperSpaceFromNavigationBar: 50px;
$formWrapperInnerWidth: 700px;
$formWrapperInnerMaxHeight: 80vh;
$formWrapperInnerBorderRadius: 10px;
$formWrapperInnerPadding: 20px;

.wrapper {
  display: flex;
}

.wrapperInner {
  margin-top: 10px;
}