.popOver {
  display: block;
  position: absolute;
  max-width: 330px;
  padding: 10px;
  border-radius: 5px;
  z-index: 100;

  box-shadow: rgba(0, 0, 0, 0.3) 0 2px 10px;
  background: rgba(255, 255, 255, 0.9);

  white-space: pre-wrap;
  word-break: break-word;
}
