// Meridian's overrides for svg icons
.available {
  svg > g {
    fill: #c2ec5e;
  }
  align-items: center;
  display: flex;
  flex-direction: column;

}

.notAvailable {
  svg > g {
    fill: #ff7394;
  }
  display: flex;
  flex-direction: column;
  align-items: center;
}

.toggleCheck {
  display: flex;
  flex-direction: column;
  align-items: center;
  
  text-align: center;
}

.buttonsContainer {
  display: flex;
  justify-content: space-between;


  button:not(:first-of-type) {
    margin-left: 5px;
  }
}


.rightSideButtonContainer {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  margin-top: 20px;
}

.leftSideButtonContainer {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  margin-top: 20px;
}
