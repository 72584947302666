$authErrorMessageContainerSpaceFromButton: 10px;
$accessRequiredTextFontSize: 14px;
$contentBlockMinHeight: 80px;
$contentBlockWrapperSpaceFromSides: 10px;
$contentBlockWrapperBorderParams: 1px solid #efe9e9;
$contentBlockWrapperBgColor: #f7f7f7;

.container {
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100vw;
  height: 100vh;
}

.wrapper {
  width: 500px;
  height: 200px;
}

.contentContainer {
  display: flex;
  flex-direction: column;
}

.contentBlock {
  min-height: $contentBlockMinHeight;

  &:last-of-type {
    font-size: $accessRequiredTextFontSize;

    // Override link font-size
    a {
      font-size: $accessRequiredTextFontSize;
    }
  }
}

.contentBlockWrapper {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;

  padding: $contentBlockWrapperSpaceFromSides;
  border-top: $contentBlockWrapperBorderParams;

  background: $contentBlockWrapperBgColor;
}

.authErrorMessage {
  margin: 0;
  margin-bottom: $authErrorMessageContainerSpaceFromButton;
}