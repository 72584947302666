@import 'src/styles/sitewide.module';

.controlsList {
  @include reset-list-props;

  display: flex;
  justify-content: space-between;
  > li {
    padding-inline: 3px;
  }
}
.button {
  width: min-content;
}
.disabledButton {
  color: rgb(127, 144, 150);
}

.lockButton {
  padding-inline: 8px;
}