@import '../../../src/styles/sitewide.module';

.gridContainer {
  @include scrollableGridContainer(55vh);
}

.loaderContainer {
  min-height: 10vh;
}

.list {
  @include reset-list-props;

  margin-top: 20px;
}

.item {
  @include reset-box-model-props;

  &:not(:first-of-type) {
    margin-top: 20px;
  }
}

.description {
  display: flex;
}

.version {
  @include reset-box-model-props;

  margin-bottom: 10px;
}

.dateTime {
  margin-left: 5px;
}