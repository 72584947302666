.storeList {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin: 0;
  padding: 0;

  list-style: none;
}

.storeItem {
  max-width: 250px;
  margin-top: 5px;
  margin-left: 5px;

  word-break: break-all;
  white-space: pre-wrap;
}

.storeItemAvailable section {
  border-color: green;
}

.storeItemUnavailable section {
  border-color: red;
}