@import '../../../src/styles/sitewide.module';

.pageHeaderContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.pageHeader {
  @include reset-box-model-props;

  margin-top: 10px;
}

.dateRange {
  @include reset-box-model-props;

  margin: 10px 0;

  font-size: 14px;
  color: #666666;
}

.stepMessage {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.previewList {
  @include reset-list-props;
}
.previewItem {}

.promoPublishPreviewContainer {
  margin-top: 20px;
}

.buttonsContainer {
  display: flex;
  justify-content: space-between;


  button:not(:first-of-type) {
    margin-left: 5px;
  }
}

.rightSideButtonContainer {
  margin-top: 20px;
  display: flex;
  align-items: flex-end;
}

.leftSideButtonContainer {
  display: flex;
  align-items: center;
}

.buttonsContainerInner {
  display: flex;
  justify-content: space-between;

  min-width: 150px;
}

.headingText {
  display: flex;
  justify-content: center;
  align-items: center;
}