@import '../../../src/styles/sitewide.module';

.gridContainer {
  @include scrollableGridContainer(55vh);
}

.container {
  display: flex;
  justify-content: center;
  align-items: center;

  margin-bottom: 20px;
}

.wrapper {
  width: 100%;
  padding: 10px;
}

.buttonContainer {
  margin-top: 5px;
}