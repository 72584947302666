.imageContainer {}
.inputWrapper {
  input {
    width: 83%;
    direction: ltr;

    &:focus {
      direction: initial;
    }
  }

  img {
    height: 20px;
    cursor: pointer;
  }
}