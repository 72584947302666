@import '../../../src/styles/sitewide.module';

$spaceFromContent: 5px;

.container {
  margin-top: 20px;
}

.messageTitle {
  @include reset-box-model-props;

  margin-bottom: $spaceFromContent;
}

.messageText:last-of-type {
  margin-bottom: 0;
}