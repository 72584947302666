.container {
  display: flex;
  margin-top: 10px;
}

.searchControl {
  width: 70%;
}

.buttonContainer,
.datePicker {
  margin-left: 10px;
}