.code {
  color: crimson;
  background-color: #f1f1f1;
  padding: 2px;
}

.codeBlock {
  white-space: pre-wrap;
  word-wrap: break-word;
  width: 400px;
  height: 200px;
  overflow: auto;
}

.instructionItem {
  padding-bottom: 10px;
}