.selection {
  width: 400px;
  padding: 8px;
  border: 1px solid #111;
  border-radius: 4px;
  margin: 4px 0;
  position: relative;
  cursor: pointer;
  &:hover {
    background-color: #FFFFCC;
  }
}
.header {
  width: 400px;
  margin-bottom: 12px;
}