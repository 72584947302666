@import 'src/styles/sitewide.module';

$spaceFromNeighbour: 10px;
$spaceFromContent: 5px;

.list {
  @include reset-list-props;
}

.item {
  @include reset-box-model-props;

  margin-top: $spaceFromNeighbour;
}

.messageTitle {
  @include reset-box-model-props;

  margin-bottom: $spaceFromContent;
}

.messageText:last-of-type {
  margin-bottom: 0;
}