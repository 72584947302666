.buttonAsLink {
  color: #111;
  background-color: transparent;
  border: none;
  cursor: pointer;
  text-decoration: underline;
  display: inline;
  margin: 0;
  padding: 0;
  outline: none;
  font-size: 16px;
  &:hover, &:focus {
    text-decoration: none;
  }
}

.link {
  display: inline-block;

  cursor: pointer;

  color: #077398;
  text-decoration: underline;

  outline: none;
  outline-offset: 2px;

  font-family: "Amazon Ember", Arial, sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
}

.svgIcon {
  color: #333;
}

.centerWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 100vw;
  height: 100vh;

  margin: 10px;
}

.checkboxLabel {
  font-size: 14px;
  color: #6b7778;
}

.validationMessage {
  font-size: 12px;
  font-weight: 500;

  margin: 0;
  margin-top: 5px;
  margin-bottom: 10px;
}

.validationWarning {
  color: darkorange;
}

.validationError {
  color: red;
}

@mixin customScrollBar() {
  /* Variables */
  --scrollbar-size: .375rem;
  --scrollbar-minlength: 1.5rem; /* Minimum length of scrollbar thumb (width of horizontal, height of vertical) */
  --scrollbar-ff-width: thin; /* FF-only accepts auto, thin, none */
  --scrollbar-track-color: transparent;
  --scrollbar-color: rgba(0,0,0,.2);
  --scrollbar-color-hover: rgba(0,0,0,.3);
  --scrollbar-color-active: rgb(0,0,0);

  /* Fancy scrollbar CSS (Mac like style) */
  overscroll-behavior: contain;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  scrollbar-width: var(--scrollbar-ff-width);
  scrollbar-color: var(--scrollbar-color) var(--scrollbar-track-color);

  &::-webkit-scrollbar {
    height: var(--scrollbar-size);
    width: var(--scrollbar-size);
  }
  &::-webkit-scrollbar-track {
    background-color: var(--scrollbar-track-color);
  }
  &::-webkit-scrollbar-thumb {
    background-color: var(--scrollbar-color);
    border-radius: 3px;
  }
  &::-webkit-scrollbar-thumb:hover {
    background-color: var(--scrollbar-color-hover);
  }
  &::-webkit-scrollbar-thumb:active {
    background-color: var(--scrollbar-color-active);
  }
  &::-webkit-scrollbar-thumb:vertical {
    min-height: var(--scrollbar-minlength);
  }
  &::-webkit-scrollbar-thumb:horizontal {
    min-width: var(--scrollbar-minlength);
  }
}

@mixin scrollableGridContainer($maxHeight: 65vh, $spaceFromTop: 20px) {
  max-height: $maxHeight;
  margin-top: $spaceFromTop;

  overflow: scroll;
}

@mixin reset-box-model-props {
  margin: 0;
  padding: 0;
}

@mixin reset-list-props {
  list-style: none;

  @include reset-box-model-props;
}

@mixin truncate($width) {
  width: $width;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@mixin shadow($borderRadius: 10px) {
  border-radius: $borderRadius;
  background: white;
  box-shadow: rgba(100, 100, 111, 0.2) 0 7px 29px 0;
}

@mixin hideScrollbar() {
  &::-webkit-scrollbar {
    background: transparent; /* Chrome/Safari/Webkit */
    height: 0px;
    width: 0px;
  }

  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none;  /* IE 10+ */
}

@mixin setWidthRangeLimit($width) {
  min-width: $width;
  max-width: $width;
}

@mixin multiLineTruncate($maxLines: 1) {
  display: -webkit-box;
  -webkit-line-clamp: var(--line-clamp, $maxLines);
  -webkit-box-orient: vertical;
  word-break: break-all;
  overflow: hidden;
  hyphens: auto;
  text-align: left;
}

@mixin multiLineShow() {
  -webkit-line-clamp:  var(--line-clamp, none);
}

/** Dashboard */
$dashboardWarningColor: #ff9f00;
$dashboardSuccessColor: #4CC790;
$dashboardInfoColor: #3c9ee5;
$dashboardErrorColor: #e52012;

/** Notifications */
$genericNotificationColor: #ecf2fc;
$mentionNotificationColor: #b2e0bc;
$readNotificationColor: #ffffff;