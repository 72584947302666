@import '../../../src/styles/sitewide.module';

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid lightgray;
  padding: 30px;
  border-radius: 5px;
}

.image {
  width: 100%;
}