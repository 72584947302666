$submitButtonContainerHeight: 50px;
$submitButtonContainerBorderBoxShadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
$submitButtonContainerSpaceAround: 5px 10px;
$submitButtonContainerBackgroundColor: rgba(255, 255, 255, 0.9);

$formContainerBgColor: white;
$formContainerSpaceFromNeighbour: 15px;
$formContainerBorderRadius: 10px;

$formButtonContainer: 80px;

$formWrapperInnerSubmitButtonWidth: 5vw;

$formGroupWrapperSpaceAround: 10px;

$formGroupTitleCounterWidth: 40px;
$formGroupTitleCounterBackground: #077398;

$formGroupTitleFontSize: 20px;
$formGroupTitleBackground: rgba(0, 0, 0, 0.05);
$formGroupTitleHeight: 30px;
$formGroupTitleSpaceAround: 10px;
$formGroupTitleSpaceFromCounter: 50px;

.wrapper {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.wrapperInner {
  &.submitButtonWrapper {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    width: $formWrapperInnerSubmitButtonWidth;

    border-top-left-radius: 0;
    border-top-right-radius: 0;

    button:not(:first-of-type) {
      margin-left: 5px;
    }
  }
}

.form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  background: transparent;
  counter-reset: section;
}

.formInnerContainer {}

.formControl {
  width: 50%;
  margin-bottom: 5px;
  padding-left: 5px;
}

.buttonContainer {}

.submitButtonContainer {
  display: flex;
  justify-content: flex-end;

  margin-top: 50px;

  overflow: hidden;
}

.formGroup {
  min-width: 500px;

  position: relative;

  margin-top: $formContainerSpaceFromNeighbour;
  border-radius: $formContainerBorderRadius;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;

  &:first-of-type {
    margin-top: auto;
  }

  background: $formContainerBgColor;
  overflow: hidden;
}

.formGroup::before {
  position: absolute;

  content: "";

  border-top: 1px solid #e2dfdf5c;
  width: 100%;
}

.formGroup:first-of-type::before,
.formGroup:last-of-type::before {
  border-top: none;
}

.formGroupWrapper {
  display: flex;
  flex-direction: column;

  border-bottom: 5px solid #d3d3d35c;
  margin-bottom: 24px;
}

.formGroupLabel {}

.formSlotContainer {
  display: flex;
  justify-content: space-between;
}

.formGroupContent {
  display: flex;
  flex-wrap: wrap;

  margin-left: 10px;
}

.formGroupSection {
  display: flex;
  justify-content: space-between;
  width: 100%;
}


.slotName {
  display: flex;
  justify-content: center;
  align-items: center;

  min-width: 100px;
  min-height: 100px;
  border: 1px solid lightgray;
}

.bannerFormContainer {
  display: flex;
  flex-direction: column;
}

.addButtonContainer {
  display: flex;
  justify-content: flex-end;
}