.loader {

}
.loaderWrapper {
  position: absolute;
  top: 60px;
  left: 50%;
  z-index: 999;
  padding: 12px;
  background-color: white;
  border: 1px solid #999;
  border-radius: 12px;
  box-shadow: 2px 2px 4px #999;
}