@import 'src/styles/sitewide.module';

.list {
  @include reset-list-props;
  @include reset-box-model-props;

  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  width: 280px;
}

.item {
  display: flex;
  margin: 1px;
}