.available {
  svg > g {
    fill: #c2ec5e;
  }
  align-items: center;
  display: flex;
  flex-direction: column;

}

.notAvailable {
  svg > g {
    fill: #a5a89f;
  }
  display: flex;
  flex-direction: column;
  align-items: center;
}

