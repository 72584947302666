$width: 300px;
$padding: 4px;

.editorWrapper {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  top: 68px;
  overflow: scroll;
  padding: 8px;
}
.columnWrapper {
  display: flex;
}
.modalContents {
  width: 300px;
}
.header {
  margin: 4px 0;
}
.warning {
  color: darkorange;
}
.option {
  padding: 4px;
  outline: none;
  cursor: pointer;
  min-height: 24px;
  line-height: 24px;
  &:hover {
    background-color: #eee;
  }
}
.options {
  overflow: scroll;
  position: absolute;
  top: 84px;
  bottom: 0;
  width: 300px;
}
.selected {
  background-color: #b9e8f7;
}
.column {
  border-right: 1px solid #ddd;
  position: relative;
  width: $width;
  padding: $padding;
  overflow-y: scroll;
}
.fieldSelector {
  width: $width;
  padding: $padding;
  display: inline-block;
}
.add {
  margin-top: 4px;
  display: flex;
}
.addInput {
  width: 250px;
  margin-right: 4px;
}
.deleteButton {
  float: right;
}
.valueWrapper {
  margin-right: 40px;
}