.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.buttonsContainer {
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
}

.buttonsContainerInner {
  display: flex;
  justify-content: space-between;

  button:last-of-type {
    margin-left: 5px;
  }
}