.headingText {
  display: flex;
  justify-content: center;
  align-items: center;

  margin-bottom: 10px;
}

.contentContainer {
  max-height: 500px;
  overflow: scroll;
}