.changeList {
  display: grid;
  grid-template-columns: auto auto auto auto auto auto;
  border-top: 1px solid black;
  border-left: 1px solid black;
}
.changeItem {
  padding: 8px;
  border-bottom: 1px solid black;
  border-right: 1px solid black;
}
.header {
  font-weight: bold;
}
.filterContainer {
  display: flex;
  align-items: center;
  padding: 8px;
}
.inputWrapper {
  width: 100%;
}
.titleWrapper {
  width: 180px;
}
.last {
  font-weight: bold;
}