.loadingBarContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  margin-top: 20px;
  padding: 20px;
  background: white;
  border-radius: 5px;

  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 100;

  transform: translate(-50%, -50%);
}

.loadingProgressBar {
  margin-top: 10px;
}