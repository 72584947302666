@import '../../../../src/styles/sitewide.module';

$spaceFromControl: 10px;
$spaceFromNeighbour: 5px;

.selectControlsContainer {
  @include reset-list-props;
  @include reset-box-model-props;

  display: flex;
  margin-left: $spaceFromControl;
}

.selectControlsItem:not(:first-of-type) {
  margin-left: $spaceFromNeighbour;
}