// Meridian's overrides for svg icons
.available {
  svg > g {
    fill: #c2ec5e;
  }
}

.notAvailable {
  svg > g {
    fill: #ff7394;
  }
}

.statusContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.extraStatusInfoText {
  text-align: center;
}

.storeList {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin: 0;
  padding: 0;

  list-style: none;
}

.storeItem {
  max-width: 250px;
  margin-top: 5px;
  margin-left: 5px;

  word-break: break-all;
  white-space: pre-wrap;
}

// Meridian overrides
.statusContainerAvailable path {
  fill: green;
}

.statusContainerUnavailable path {
  fill: red;
}

.storeItemAvailable section {
  border-color: green;
}

.storeItemUnavailable section {
  border-color: red;
}