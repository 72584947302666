@import '../../../src/styles/sitewide.module';

.panel {
  display: flex;
  justify-content: flex-end;
  align-items: center;

  margin-top: 10px;
}

.info {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.datePicker {
  display: flex;
  width: 500px;

  margin-top: 20px;
}

.calendarContainer {
  margin-top: 20px;

  > div {
    height: initial;
  }

  // Meridian's overrides
  :global {
    // Distributed event's content container
    div[mdn-calendar-distributed-event] > div {
      background-color: #b9e8f7;
    }
    // Distributed event's buttons' border
    div[mdn-calendar-distributed-event] > button[type="button"] {
      border-top: 1px solid rgb(152, 201, 30) !important;
    }

    span[aria-current="date"] {
      font-size: 30px;
      background-color: #b9e8f7;
    }
  }
}

.weekTitle {
  @include reset-box-model-props;
}

.weekContent {
  @include reset-box-model-props;

  margin-top: 10px;
}