@import 'src/styles/sitewide.module';

.controlsList {
  @include reset-list-props;

  display: flex;
  justify-content: space-between;
}

.item:not(:first-of-type) {
  margin-left: 5px;
}